/* You can add global styles to this file, and also import other style files */
@import "./wunder-theme";
// @import "~bootstrap/scss/bootstrap";

html,
body {
  font-family: "Roboto", sans-serif;
  height: 100%;
}

body {
  margin: 0;
}

main {
  background-color: #f7f9fc !important;
}

.avatar {
  width: 35px !important;
  height: 35px !important;
}

mat-header-cell {
  font-weight: bold;
}
// ICONS //
.feather {
  width: 25px !important;
  height: 25px !important;
}

.iconButton {
  background: none;
  border: 0;
}

.iconFlat {
  fill: #6e757d !important;
  stroke: #6e757d !important;
  stroke-width: 2px !important;
}

.iconBack {
  fill: #6e757d !important;
  stroke: white !important;
  stroke-width: 2px !important;
}

.iconBack2 {
  fill: #a5a9af !important;
  stroke: #6e757d !important;
  stroke-width: 2px !important;
}

.iconBackGray {
  fill: #f7f9fc !important;
  stroke: #6e757d !important;
  stroke-width: 2px !important;
}
.mat-form-field-infix {
  border-top: 0;
  width: auto;
}
.mat-paginator-container {
  justify-content: center !important;
}

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";
